<template>
  <div class="home">
    <div class="userInfo">
      <img v-if="customer_info.head_img" :src="customer_info.head_img" alt="" />
      <img v-else src="@/assets/img/avat.png" />
      <div class="info">
        <div class="name">{{ customer_info.name }}</div>
        <div class="des">
          <ul>
            <li>
              <div>
                <label>{{ $t("Company") }}: </label>
                <a-tooltip v-if="customer_info.company">
                  <template slot="title">
                    {{ customer_info.company }}
                  </template>
                  <span>{{
                    customer_info.company ? customer_info.company : "--"
                  }}</span>
                </a-tooltip>
                <span v-else>{{ "--" }}</span>
              </div>
            </li>
            <li :title="customer_info.position">
              <div>
                <label>{{ $t("Position") }}: </label>
                <a-tooltip v-if="customer_info.position">
                  <template slot="title">
                    {{ customer_info.position }}
                  </template>
                  <span>{{
                    customer_info.position ? customer_info.position : "--"
                  }}</span>
                </a-tooltip>
                <span v-else>{{ "--" }}</span>
              </div>
            </li>
            <li>
              <div>
                <label>{{ $t("Location") }}: </label>
                <a-tooltip v-if="customer_info.location">
                  <template slot="title">
                    {{ customer_info.location }}
                  </template>
                  <span>{{
                    customer_info.location ? customer_info.location : "--"
                  }}</span>
                </a-tooltip>
                <span v-else>{{ "--" }}</span>
                <!-- <span>{{
                  customer_info.location ? customer_info.location : "--"
                }}</span> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wrap-message">
      <ul>
        <template v-for="(item, index) in messages">
          <li v-if="item.msg_type == 2" class="left" :key="index">
            <div class="box">
              <img
                v-if="customer_info.head_img"
                :src="customer_info.head_img"
                alt=""
              />
              <img v-else src="@/assets/img/avat.png" />
              <div class="wrap-info" :class="[lang]">
                <div class="name" :class="[lang]">{{ customer_info.name }}</div>
                <div class="message" :class="[lang]">
                  <pre :class="[lang]">{{ item.content }}</pre>
                </div>
              </div>
            </div>
            <div class="time">{{ item.create_date }}</div>
          </li>
          <li class="right" v-if="item.msg_type == 1" :key="index">
            <div class="box">
              <div class="wrap-info" :class="[lang]">
                <div class="name" :class="[lang]">{{ linkedin_info.name }}</div>
                <div class="message" :class="[lang]">
                  <pre :class="[lang]">{{ item.content }}</pre>
                </div>
              </div>
              <img
                v-if="linkedin_info.head_img"
                :src="linkedin_info.head_img"
                alt=""
              />
              <img v-else src="@/assets/img/avat.png" />
            </div>
            <div class="time">{{ item.create_date }}</div>
            <div style="clear: both"></div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      customer_info: {},
      messages: [],
      linkedin_info: {},
      campaign_info: {},
    };
  },
  computed: {
    ...mapState("setting", ["lang"]),
  },
  created() {
    this.getData();
  },
  i18n: require("./i18n"),
  methods: {
    getTimeDes(time) {
      var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
      var hour = minute * 60;
      var day = hour * 24;
      var week = day * 7;
      // var halfamonth = day * 15;
      var month = day * 30;
      var now = new Date().getTime(); //获取当前时间毫秒
      console.log(now);
      let dateTimeStamp = new Date(time).getTime();
      var diffValue = now - dateTimeStamp; //时间差
      let result;
      if (diffValue < 0) {
        return;
      }
      var minC = diffValue / minute; //计算时间差的分，时，天，周，月
      var hourC = diffValue / hour;
      var dayC = diffValue / day;
      var weekC = diffValue / week;
      var monthC = diffValue / month;
      if (monthC >= 1 && monthC <= 3) {
        // result = " " + parseInt(monthC) +
        result = this.$t("monthsago").replace("[]", parseInt(monthC));
        // `月前`;
      } else if (weekC >= 1 && weekC <= 3) {
        // result = " " + parseInt(weekC) + `周前`;
        result = this.$t("weeksago").replace("[]", parseInt(weekC));
      } else if (dayC >= 1 && dayC <= 6) {
        // result = " " + parseInt(dayC) + `天前`;
        result = this.$t("daysago").replace("[]", parseInt(dayC));
      } else if (hourC >= 1 && hourC <= 23) {
        // result = " " + parseInt(hourC) + `小时前`;
        result = this.$t("hoursago").replace("[]", parseInt(hourC));
      } else if (minC >= 1 && minC <= 59) {
        // result = " " + parseInt(minC) + `分钟前`;
        result = this.$t("minutesago").replace("[]", parseInt(minC));
      } else if (diffValue >= 0 && diffValue <= minute) {
        result = this.$t("Justnow");
      } else {
        var datetime = new Date();
        datetime.setTime(dateTimeStamp);
        var Nyear = datetime.getFullYear();
        var Nmonth =
          datetime.getMonth() + 1 < 10
            ? `0` + (datetime.getMonth() + 1)
            : datetime.getMonth() + 1;
        var Ndate =
          datetime.getDate() < 10
            ? `0` + datetime.getDate()
            : datetime.getDate();
        var Nhour =
          datetime.getHours() < 10
            ? `0` + datetime.getHours()
            : datetime.getHours();
        var Nminute =
          datetime.getMinutes() < 10
            ? `0` + datetime.getMinutes()
            : datetime.getMinutes();
        var Nsecond =
          datetime.getSeconds() < 10
            ? `0` + datetime.getSeconds()
            : datetime.getSeconds();
        result =
          Nyear +
          `-` +
          Nmonth +
          `-` +
          Ndate +
          " " +
          Nhour +
          ":" +
          Nminute +
          ":" +
          Nsecond;
      }
      return result;
    },
    getData() {
      let data = this.$route.query || {};
      let { campaign_id, customer_id } = data;
      this.$axios(
        "/recruiter/conversatin_detail",
        {
          campaign_id: parseInt(campaign_id),
          customer_id: parseInt(customer_id),
        },
        "post"
      ).then((res) => {
        if (res.code == 200) {
          let { customer_info, messages, linkedin_info, campaign_info } =
            res.data;
          this.customer_info = customer_info || {};
          this.messages = messages || [];
          this.linkedin_info = linkedin_info || {};
          this.campaign_info = campaign_info || {};
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: 77px;
  padding: 20px 30px;
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .userInfo {
    display: flex;
    align-items: center;
    img {
      width: 87px;
      height: 87px;
      border: #fff solid 2px;
      margin-right: 47px;
      border-radius: 50%;
    }
    .info {
      .name {
        font-family: Arial, Arial;
        font-weight: bold;
        font-size: 28px;
        color: #000000;
        margin-top: 10px;
      }
      .des {
        margin-top: 20px;
        ul {
          li {
            display: inline-block;
            width: 320px;
            height: 16px;
            line-height: 16px;
            div {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
            }
            label {
              font-family: Arial, Arial;
              font-weight: 400;
              font-size: 16px;
              color: #666666;
            }
            span {
              font-family: Arial, Arial;
              font-weight: bold;
              font-size: 16px;
              color: #000000;
            }
          }
          padding-bottom: 9px;
        }
      }
    }
  }
  .wrap-message {
    margin-top: 30px;
    padding: 30px 30px 30px 30px;
    background: #fff;
    li {
      margin-bottom: 80px;
      .box {
        padding: 24px;
        box-sizing: border-box;
        width: 668px;
        display: flex;
        align-items: top;
        border-radius: 4px;
        display: flex;
        align-items: top;
        img {
          width: 57px;
          height: 57px;
          border-radius: 50%;
        }
        .message {
          font-family: Arial, Arial;
          font-weight: 400;
          font-size: 14px;
          color: #36384d;
          line-height: 24px;
          margin-top: 14px;
          pre {
            white-space: pre-wrap;
          }
        }
        .name {
          font-family: Arial, Arial;
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
    .left {
      position: relative;
      .box {
        background: #eeeff6;

        .wrap-info {
          margin-left: 18px;
        }
        .message {
          color: #36384d;
        }
        .name {
          color: #000000;
        }
      }
      .time {
        position: absolute;
        bottom: -30px;
        left: 99px;
      }
    }
    .right {
      position: relative;
      .box {
        background: #0e756a;
        float: right;
        .wrap-info {
          margin-right: 18px;
          width: 542px;
          .AR {
            text-align: right;
          }
        }
        .message {
          color: #bcdeda;
        }
        .name {
          color: #fff;
          text-align: right;
        }
      }
      .box::after {
        content: "";
        clear: both;
      }
      .time {
        position: absolute;
        bottom: -30px;
        right: 99px;
      }
    }
  }
}
</style>
