var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "userInfo" }, [
      _vm.customer_info.head_img
        ? _c("img", { attrs: { src: _vm.customer_info.head_img, alt: "" } })
        : _c("img", { attrs: { src: require("@/assets/img/avat.png") } }),
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.customer_info.name))
        ]),
        _c("div", { staticClass: "des" }, [
          _c("ul", [
            _c("li", [
              _c(
                "div",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Company")) + ": ")]),
                  _vm.customer_info.company
                    ? _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              " " + _vm._s(_vm.customer_info.company) + " "
                            )
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.customer_info.company
                                  ? _vm.customer_info.company
                                  : "--"
                              )
                            )
                          ])
                        ],
                        2
                      )
                    : _c("span", [_vm._v(_vm._s("--"))])
                ],
                1
              )
            ]),
            _c("li", { attrs: { title: _vm.customer_info.position } }, [
              _c(
                "div",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Position")) + ": ")]),
                  _vm.customer_info.position
                    ? _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              " " + _vm._s(_vm.customer_info.position) + " "
                            )
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.customer_info.position
                                  ? _vm.customer_info.position
                                  : "--"
                              )
                            )
                          ])
                        ],
                        2
                      )
                    : _c("span", [_vm._v(_vm._s("--"))])
                ],
                1
              )
            ]),
            _c("li", [
              _c(
                "div",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Location")) + ": ")]),
                  _vm.customer_info.location
                    ? _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              " " + _vm._s(_vm.customer_info.location) + " "
                            )
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.customer_info.location
                                  ? _vm.customer_info.location
                                  : "--"
                              )
                            )
                          ])
                        ],
                        2
                      )
                    : _c("span", [_vm._v(_vm._s("--"))])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "wrap-message" }, [
      _c(
        "ul",
        [
          _vm._l(_vm.messages, function(item, index) {
            return [
              item.msg_type == 2
                ? _c("li", { key: index, staticClass: "left" }, [
                    _c("div", { staticClass: "box" }, [
                      _vm.customer_info.head_img
                        ? _c("img", {
                            attrs: { src: _vm.customer_info.head_img, alt: "" }
                          })
                        : _c("img", {
                            attrs: { src: require("@/assets/img/avat.png") }
                          }),
                      _c(
                        "div",
                        { staticClass: "wrap-info", class: [_vm.lang] },
                        [
                          _c(
                            "div",
                            { staticClass: "name", class: [_vm.lang] },
                            [_vm._v(_vm._s(_vm.customer_info.name))]
                          ),
                          _c(
                            "div",
                            { staticClass: "message", class: [_vm.lang] },
                            [
                              _c("pre", { class: [_vm.lang] }, [
                                _vm._v(_vm._s(item.content))
                              ])
                            ]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.create_date))
                    ])
                  ])
                : _vm._e(),
              item.msg_type == 1
                ? _c("li", { key: index, staticClass: "right" }, [
                    _c("div", { staticClass: "box" }, [
                      _c(
                        "div",
                        { staticClass: "wrap-info", class: [_vm.lang] },
                        [
                          _c(
                            "div",
                            { staticClass: "name", class: [_vm.lang] },
                            [_vm._v(_vm._s(_vm.linkedin_info.name))]
                          ),
                          _c(
                            "div",
                            { staticClass: "message", class: [_vm.lang] },
                            [
                              _c("pre", { class: [_vm.lang] }, [
                                _vm._v(_vm._s(item.content))
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm.linkedin_info.head_img
                        ? _c("img", {
                            attrs: { src: _vm.linkedin_info.head_img, alt: "" }
                          })
                        : _c("img", {
                            attrs: { src: require("@/assets/img/avat.png") }
                          })
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.create_date))
                    ]),
                    _c("div", { staticStyle: { clear: "both" } })
                  ])
                : _vm._e()
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }